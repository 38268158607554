<template>
  <div class="noticeinfo">
    <div class="noticeinfo_center">
      <header>教研圈公告</header>
      <main>
        <div v-if="this.total==0">
          <el-empty description="暂无数据"></el-empty>
        </div>
        <div v-else>
          <div v-for="item in noticedata" :key="item.id" class="moreto">
            <p class="m_title" @click="gotopath(item.id)">{{item.title}}</p>
            <p class="m_time">{{item.createTime}}</p>
          </div>
          <div>
            <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="curpage"
              :page-sizes="[10, 20, 30, 40, 50]"
              :page-size="pagesize"
              layout="total, prev, pager, next, sizes, jumper"
              :total="total"
            ></el-pagination>
          </div>
        </div>
      </main>
      <!-- <footer></footer> -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // 通告数据
      noticedata: null,
      //分页
      curpage: 1,
      pagesize: 10,
      total: null,
    };
  },
  created() {
    console.log(this.$route.query, "路由参数");
    this.queryCircleNotic(this.$route.query.introId);
  },
  methods: {
    //查询公告
    async queryCircleNotic(id) {
      const { data, status } = await this.$Http.queryCircleNotic({
        pageNo: this.curpage,
        pageSize: this.pagesize,
        researchId: id,
      });
      if (status == 200) {
        console.log(data, status, "查询公告");
        if (data == null) {
          this.noticedata = data;
          this.total = 0;
        } else {
          this.noticedata = data.list;
          this.total = data.total;
        }
      }
    },
    //切换地址
    gotopath(id) {
      this.$router.push({
        path: "/circlenotice",
        query: {
          id: id,
        },
      });
    },

    // 分页
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pagesize = val;
      this.queryCircleNotic(this.$route.query.introId);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.curpage = val;
      this.queryCircleNotic(this.$route.query.introId);
    },
  },
};
</script>
<style lang="scss" scoped>
.noticeinfo {
  .noticeinfo_center {
    // width: 1200px;
    background-color: #fff;
    margin-top: 20px;

    header {
      padding: 15px 20px;
      border-bottom: 1px solid #d7dce4;
    }
    main {
      padding: 15px 20px;
      .moreto {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        .m_title {
          max-width: 940px;
          // width: 1022px;
          // height: 28px;
          font-size: 16px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #141516;
          // line-height: 19px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          cursor: pointer;
        }
        .m_title:hover {
          color: blue;
        }
        .m_time {
          width: 160px;
          // width: 89px;
          // height: 21px;
          font-size: 16px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #8d9199;
          // line-height: 19px;
        }
      }
      .el-pagination {
        margin-top: 20px;
        display: flex;
        justify-content: center;
      }
    }
  }
}
</style>