<template>
  <div style="border: 1px solid #ccc;">
    <Toolbar style="border-bottom: 1px solid #ccc" :editor="editor" :defaultConfig="toolbarConfig" :mode="mode" />
    <Editor style="height: 500px; overflow-y: hidden;" v-model="html" :defaultConfig="editorConfig" :mode="mode" @onCreated="onCreated" />
  </div>
</template>
<script>
import { mapState } from "vuex";
import Vue from "vue";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
export default Vue.extend({
  components: { Editor, Toolbar },
  props: {
    showtext: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editor: null, //实例
      html: "", //默认的内容
      toolbarConfig: {
        // 排除菜单组，写菜单组 key 的值即可
        excludeKeys: [
          "headerSelect",
          "blockquote",
          "|",
          "bold",
          "underline",
          "italic",
          "group-more-style",
          "color",
          "bgColor",
          "|",
          "fontSize",
          "fontFamily",
          "lineHeight",
          "|",
          "bulletedList",
          "numberedList",
          "todo",
          "group-justify",
          "group-indent",
          "|",
          // "emotion",
          "insertLink",
          // "group-image",
          // "group-video",
          "insertTable",
          "codeBlock",
          "divider",
          "|",
          "undo",
          "redo",
          "fullScreen",
        ],
      }, //工具栏配置
      //编译器配置
      editorConfig: {
        //
        placeholder: "请输入内容...",
        // 所有的菜单配置，都要在 MENU_CONF 属性下
        MENU_CONF: {},
      },
      mode: "default", // or 'simple' //模式
    };
  },
  created() {
    console.log(this.toolbar.getConfig().toolbarKeys,'lll')
    this.aa();
  },
  methods: {
    //
    aa() {
      this.editorConfig.MENU_CONF["uploadImage"] = {
        // 上传图片的配置
        server: "http://8.142.189.92:9090/api/file/upload",

        // form-data fieldName ，默认值 'wangeditor-uploaded-image'
        fieldName: "file",

        // 单个文件的最大体积限制，默认为 2M
        // maxFileSize: 1 * 1024 * 1024, // 1M

        // 最多可上传几个文件，默认为 100
        // maxNumberOfFiles: 10,

        // 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []
        // allowedFileTypes: ["image/*"],

        // 自定义上传参数，例如传递验证的 token 等。参数会被添加到 formData 中，一起上传到服务端。
        meta: {
          //   file: "xxx",
          fileType: 0,
        },

        // 将 meta 拼接到 url 参数中，默认 false
        // metaWithUrl: false,

        // 自定义增加 http  header
        // headers: {
        //   Accept: "text/x-json",
        //   otherKey: "xxx",
        // },

        // 跨域是否传递 cookie ，默认为 false
        // withCredentials: true,

        // 超时时间，默认为 10 秒
        // timeout: 5 * 1000, // 5 秒

        // 自定义插入图片
        customInsert(res, insertFn) {
          console.log(res, "aaaa", insertFn, "kkk");
          // JS 语法
          // res 即服务端的返回结果

          // 从 res 中找到 url alt href ，然后插图图片
          insertFn(res.data.resourceUrl, null, null);
        },

        // 单个文件上传成功之后
        onSuccess(file, res) {
          // JS 语法
          console.log(`${file.name} 上传成功`, res);
        },

        // 单个文件上传失败
        onFailed(file, res) {
          // JS 语法
          console.log(`${file.name} 上传失败`, res);
        },
      };
    },
    //
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
    },
  },
  mounted() {
    // 模拟 ajax 请求，异步渲染编辑器
    // setTimeout(() => {
    //   this.html = "<p>模拟 Ajax 异步设置内容 HTML</p>";
    // }, 1500);
  },
  // 销毁
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器 --内存泄漏
  },
  computed: {
    ...mapState({
      editors: (state) => state.userinfo.editors,
    }),
  },
  watch: {
    //
    editors(val) {
      console.log(this.editors, "editors", val);
      if (val) {
        this.html = "";
      }
    },
    //
    html() {
      //   console.log(this.html, "富文本编辑");
      this.$emit("gethtml", this.html);
    },
    //
    showtext(val) {
      console.log(val, "文本编辑");
      if (val) {
        this.html = "";
      }
    },
  },
});
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
